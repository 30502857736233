import { SalvarLeadRequest } from '@services/postSalvarLeadRefinanciamento'
import { ApplicationState } from '@store/types'
import { TrackRefinRequest } from '@services/postTrackingRefin'
import { StepRefin, TipoEmprestimo } from '@utils/enums'
import { datePtToEn } from '@utils/functions'

export const buildLeadRequest = ({
  ui,
  refinanciamento,
  session,
}: ApplicationState): SalvarLeadRequest => ({
  contracts:
    refinanciamento.contratos.contratosSelecionados?.map((contrato) => contrato.numeroContrato) ||
    [],
  socialNumber: refinanciamento.dadosPessoais.cpf || '',
  email: refinanciamento.contato.email || '',
  phone: refinanciamento.contato.telefone || '',
  numberOfInstallments: refinanciamento.simulacao.parcelaAtual.numberOfInstallments,
  installmentAmount: refinanciamento.simulacao.parcelaAtual.installmentAmount,
  requestedAmount: refinanciamento.simulacao.parcelaAtual.requestedAmount,
  debtBalance: refinanciamento.simulacao.debtBalance,
  moneyChange: refinanciamento.simulacao.parcelaAtual.availableAmount,
  percentageIOF: refinanciamento.simulacao.parcelaAtual.percentageIOF,
  cETAm: refinanciamento.simulacao.parcelaAtual.cETAm,
  contractualInterest: refinanciamento.simulacao.parcelaAtual.contractualInterest,
  categoryId: refinanciamento.contratos.convenioCodigo,
  categoryDescription: refinanciamento.contratos.convenioSelecionado || '',
  agencyId: refinanciamento.contratos.orgaoCodigo,
  agencyDescription: refinanciamento.contratos.convenioSelecionado || '',
  firstName: refinanciamento.dadosPessoais.nome.split(' ').slice(0, 1).join(' ') || '',
  secondName:
    refinanciamento.dadosPessoais.nome
      .split(' ')
      .slice(1, refinanciamento.dadosPessoais.nome.length)
      .join(' ') || '',
  origin: ui.navigation.origem,
  utmSource: String(session.utmSource) || '',
  utmMedium: String(session.utmMedium) || '',
  utmContent: String(session.utmContent) || '',
  utmCampaign: String(session.utmCampaign) || '',
  facebookId: String(session.facebookId) || '',
  gaClientId: String(session.gaClientId) || '',
  gaClid: String(session.gaClid) || '',
  clientId: session.sessionId || '',
  birthDate: refinanciamento.dadosPessoais?.dataNascimento
    ? datePtToEn(refinanciamento.dadosPessoais?.dataNascimento)
    : '',
  availableWhatsapp: !refinanciamento.contato?.checkTelefone,
  firstDueDate: refinanciamento.simulacao.parcelaAtual.firstDueDate,
  lastDueDate: refinanciamento.simulacao.parcelaAtual.lastDueDate,
  insuredCredit:
    refinanciamento.simulacao.simulacaoSelecionada === TipoEmprestimo.CREDITO_PROTEGIDO,
  finalTotal:
    refinanciamento.simulacao.parcelaAtual.financedAmount +
    refinanciamento.simulacao.parcelaAtual.contractualInterest,
  productCode: refinanciamento.contratos.codigoProduto,
  campaign: session.campanha || '',
  registration: refinanciamento.contratos.matriculaSelecionada,
})

export const buildTrackingRequest = (
  { ui, refinanciamento, session }: ApplicationState,
  step: StepRefin,
): TrackRefinRequest => ({
  clientId: session.sessionId || '',
  step,
  eventType: 'SIMULADOR_REFINANCIAMENTO',
  quantidadeParcelas: refinanciamento.simulacao.totalParcelas || 0,
  saldoDevedorPreRefin: refinanciamento.simulacao.debtBalance || 0,
  sessionId: session.sessionId || '',
  iof: refinanciamento.simulacao.parcelaAtual.amountIOF || 0,
  taxaJuros: refinanciamento.simulacao.parcelaAtual.monthlyNominalRate || 0,
  valorEmprestimo: refinanciamento.simulacao.parcelaAtual.requestedAmount || 0,
  valorLiberado: refinanciamento.simulacao.parcelaAtual.availableAmount || 0,
  valorTotalSolicitado: refinanciamento.simulacao.parcelaAtual.requestedAmount || 0,
  valorTotalRefinanciado: refinanciamento.simulacao.debtBalance || 0,
  valorQuitacao: refinanciamento.simulacao.debtBalance || 0,
  valorCET: refinanciamento.simulacao.parcelaAtual.cETAa || 0,
  custoEfetivoTotal: refinanciamento.simulacao.parcelaAtual.cETAa || 0,
  taxaAtual: refinanciamento.simulacao.parcelaAtual.cETAm || 0,
  valorProximaParcela: refinanciamento.simulacao.parcelaAtual.installmentAmount || 0,
  numeroContrato:
    refinanciamento.contratos.contratosSelecionados
      ?.map((contrato) => contrato.numeroContrato)
      .toString() || '',
  vencimentoProxParcela:
    refinanciamento?.contratos?.listaContratos
      ?.map((contrato) =>
        contrato?.listMatricula
          .map((c) => c?.listContrato.map((lc) => lc?.vencimentoProximaParcela).toString())
          .toString(),
      )
      .toString() || '',
  origem: ui?.navigation?.origem || '',
})
