import React from 'react'

import ISafe from '@interco/icons/core/privacy-security/ic_i_safe'
import PrepaymentOfReceivables from '@interco/icons/core/action-navigation/ic_calendar_money'
import { Colors } from '@utils/Colors'

export enum FontesPagamento {
  DEFAULT = 0,
  ESTADUAL = 1,
  MUNICIPAL = 2,
  INSS = 3,
  FEDERAL_SIAPE = 4,
  FORCAS_ARMADAS = 7,
}

export enum ErrorCodes {
  // Refinanciamento
  RE_001_CONTRATOS = 'RE.001', // src/store/refinanciamento/simulacao/sagas/getContratos.ts
  RE_002_SIMULACAO = 'RE.002', // src/store/refinanciamento/simulacao/sagas/postSimulacao.ts
  RE_003_SALVAR_LEAD = 'RE.003', // src/store/refinanciamento/simulacao/sagas/postSalvarLead.ts

  RE_004_ENVIAR_ASSINATURA = 'RE.004', // src/store/refinanciamento/documentosAssinatura/sagas/sendDocumentosAssinatura.ts
  RE_005_ENVIAR_ASSINCRONO = 'RE.005', // src/store/refinanciamento/documentosAssinatura/sagas/postDocumentoAssincronoSaga.ts
  RE_006_BUSCAR_IDSIMULACAO = 'RE.006', // src/store/refinanciamento/simulacao/sagas/getSimulacaoId.ts
  RE_007_CRIAR_PROPOSTA = 'RE.007', // src/store/refinanciamento/proposta/sagas/postCriarProposta.ts
  RE_008_SALVAR_PROPOSTA = 'RE.008', // src/store/refinanciamento/proposta/sagas/postSalvarPropostaSaga.ts
}

export enum ConveniosCodes {
  ESTADO_MG = 1,
  TJMG = 2,
  SIAPE_SERVIDOR_FEDERAL = 3,
  ESTADO_RJ = 4,
  PREF_BH = 5,
  INSS = 6,
  ESTADO_SP = 10,
  ESTADO_BAHIA = 12,
  MARINHA_BR = 13,
  ESTADO_MS = 14,
  PREF_SALVADOR = 16,
  PREF_SP = 17,
  ESTADO_GO = 20,
  FORCA_AEREA_BR = 23,
  PREF_CONTAGEM = 34,
  EXERCITO_BR = 37,
  HOSPITAL_ODILON = 42,
  MIN_PUB_MG = 43,
  TRE_MG = 45,
  METRO = 46,
  TRE_SP = 55,
  STJ = 56,
  TCE_MG = 62,
  TRT = 75,
  ESTADO_MT = 105,
}

export enum TipoEmprestimo {
  CREDITO_PROTEGIDO = 'simulationsWithInsurance',
  SEM_CREDITO_PROTEGIDO = 'simulations',
}

export enum StepRefin {
  CONTATOS = 'contatos',
  CONTRATAR_CONTATOS = 'contratar-contatos',
  VALIDADO_SMS_CONTATOS = 'validado-sms-contatos',
  VALIDADO_EMAIL_CONTATOS = 'validado-email-contatos',
  CONTRATOS = 'contratos',
  SIMULAR_CONTRATOS = 'simular-contratos',
  CONTRATOS_NAO_ELEGIVEIS = 'contratos-nao-elegiveis',
  PARCELAMENTO = 'parcelamento',
  SELECIONADO_PARCELAMENTO = 'selecionado-parcelamento',
  RESULTADO = 'resultado',
  INICIO_CAPTURA = 'inicio-captura-dados',
  ENDERECO = 'envio-dados-endereco',
  DADOS_BANCARIOS = 'envio-dados-bancarios',
  SOLICITAR_CONTRATACAO = 'solicitar-contratacao',
  DADOS_RECEBIDO = 'dados-recebido',
  NAO_LIBERADO = 'nao-liberado',
  ORIENTACOES_VIDEO_SELFIE = 'orientacoes-gravar-video-selfie',
  GRAVAR_VIDEO_SELFIE = 'gravar-video-selfie',
  CONCLUSAO_ENVIO_DOCUMENTOS = 'conclusao-envio-biometria',
  REVISAO_ASSINATURA = 'revisao-assinatura',
  PROPOSTA_FINALIZADA = 'proposta-finalizada',
  INICIO_CAPTURA_DOCUMENTOS = 'inicio-captura-documentos',
  FOTOGRAFAR_DOCUMENTO = 'fotografar-documento',
  ANEXAR_DOCUMENTO = 'anexar-documento',
  ANEXO_DE_DOCUMENTOS_CONCLUIDO = 'anexo-documentos-concluido',
}

export const ConveniosMultiContratosBloqueado = [
  'Pref Belo Horizonte',
  'Estado da Bahia',
  'Marinha do Brasil',
  'Pref Salvador',
  'Força Aérea Brasileira',
  'Pref de Contagem',
  'Hospital Odilon Behrens',
  'TRE - MG',
  'Metro',
  'STJ - Superior Tribunal de Justiça',
  'TCE- Tribunal de Contas do Estado de Minas Gerais',
  'SJMG - Seção Judiciária de Minas Gerais - MG',
  'STF - Supremo Tribunal Federal',
  'Estado de Mato Grosso',
]

export const EmprestimoProtegidoBeneficios = [
  {
    icone: <ISafe height={24} width={24} color={Colors.GRAY500} />,
    titulo: 'Tenha tranquilidade',
    descricao: 'Em caso de morte ou invalidez, seu empréstimo é quitado',
  },
  {
    icone: <PrepaymentOfReceivables height={24} width={24} color={Colors.GRAY500} />,
    titulo: 'Crédito rápido',
    descricao:
      'Seu crédito é liberado em até 3 dias, caso esteja tudo certo com a sua documentação',
  },
]

export const EmprestimoBeneficios = [
  {
    icone: <PrepaymentOfReceivables height={24} width={24} color={Colors.GRAY500} />,
    titulo: 'Crédito rápido',
    descricao:
      'Seu crédito é liberado em até 3 dias, caso esteja tudo certo com a sua documentação',
  },
]

export enum CCBEnum {
  CCBEXERCITO = 'exercito',
  CCB = 'ccb',
}

export enum ConsignanteApi {
  SEF = 'SEPLAG',
  CBMMG = 'Bombeiros',
  IPSEMG = 'IPSEMG',
  PMMG = 'PMMG',
  IPSM = 'IPSM',
  DPMG = 'Defensoria Publica MG',
}

export enum ConsignantePdf {
  SEF = 'SEF',
  CBMMG = 'CBMMG',
  IPSEMG = 'IPSEMG',
  PMMG = 'PMMG',
  IPSM = 'IPSM',
  DPMG = 'DPMG',
}

export enum Consignado {
  SERVIDOR = 'servidor',
  BOLSISTA = 'bolsista',
  PENSIONISTA = 'pensionista',
  CONTRATADO = 'contratado',
}

export enum TipoDocumento {
  RG = 'rg',
  CNH = 'cnh',
  CARTEIRA_FUNCIONAL = 'rne',
  CONTRACHEQUE = 'contracheque',
}

export enum TipoMiniatura {
  RG_FRENTE = 'rg-frente',
  RG_VERSO = 'rg-verso',
  CNH_FRENTE = 'cnh-frente',
  CNH_VERSO = 'cnh-verso',
  RNE_FRENTE = 'rne-frente',
  RNE_VERSO = 'rne-verso',
}

export enum PendenciaEnumV3 {
  PENDENCIA_IRREVERSIVEL = 'PENDENCIA_IRREVERSIVEL',
  PENDENCIA_TEIMOSINHA = 'PENDENCIA_TEIMOSINHA',
  PENDENCIA_VALIDACAO = 'PENDENCIA_VALIDACAO',
}
