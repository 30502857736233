import React, { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Box } from '@atoms'
import { Page } from '@templates'
import { ApplicationState } from '@store/types'
import ISafe from '@interco/icons/core/privacy-security/ic_i_safe'
import PrepaymentOfReceivables from '@interco/icons/core/action-navigation/ic_calendar_money'
import { TipoEmprestimo } from '@utils/enums'
import { Radio } from '@interco/inter-ui/components/Radio'
import { Tag } from '@interco/inter-ui/components/Tag'
import TagFunction from '@utils/Tag'
import {
  salvarLeadRequest,
  selectSimulacao,
  setParcelaSelecionada,
  setTotalParcelas,
} from '@store/refinanciamento/simulacao/actions'
import { getParcelaAtualSimulacoes } from '@utils/functions'
import { Button } from '@interco/inter-ui/components/Button'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { ListaDescricao } from '@molecules'
import { Colors } from '@interco/cp-react-ui-lib'
import { BridgeService } from '@services/bridge'
import { interWbSession } from '@interco/inter-webview-bridge'
import routes from '@routes/RefinV2/routes'

const OfertaSeguro = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    simulacaoSelecionada = TipoEmprestimo.CREDITO_PROTEGIDO,
    simulations,
    simulationsWithInsurance,
  } = useSelector((state: ApplicationState) => state.refinanciamento.simulacao)

  const { loading } = useSelector((state: ApplicationState) => state.refinanciamento.valida)

  const selecionarParcelasPorSimulacao = () => {
    if (
      simulacaoSelecionada === TipoEmprestimo.CREDITO_PROTEGIDO &&
      simulationsWithInsurance.length > 0
    ) {
      const getParcelaAtual = getParcelaAtualSimulacoes(simulationsWithInsurance)
      dispatch(setParcelaSelecionada(getParcelaAtual))
      dispatch(setTotalParcelas(getParcelaAtual.numberOfInstallments))
      dispatch(
        salvarLeadRequest({
          callback: () => {
            navigate(routes.RESULTADO_SIMULACAO)
          },
        }),
      )
    } else if (
      simulacaoSelecionada === TipoEmprestimo.SEM_CREDITO_PROTEGIDO &&
      simulations.length > 0
    ) {
      const getParcelaAtual = getParcelaAtualSimulacoes(simulations)
      dispatch(setParcelaSelecionada(getParcelaAtual))
      dispatch(setTotalParcelas(getParcelaAtual.numberOfInstallments))
      dispatch(
        salvarLeadRequest({
          callback: () => {
            navigate(routes.RESULTADO_SIMULACAO)
          },
        }),
      )
    } else {
      navigate(routes.NAO_LIBERADO)
    }
  }

  useEffect(() => {
    if (!BridgeService.isBrowser()) {
      interWbSession.editToolbar('Refinanciamento Consignado', true)
    }
  }, [])

  return (
    <Page
      stickyFooter={
        <Button
          variant="primary"
          isLoading={loading}
          disabled={loading}
          onClick={() => {
            TagFunction.enviarTagueamentoApp({
              name: 'CONSIGNADO_REFIN_FLUXO',
              ref_figma: '2',
              tela: 'Oferta de seguro',
              ref_type: 'Botao',
              content_action: 'Toque',
              content_name: 'Simular melhor condição',
              product: 'CONSIGNADO_REFIN_INSS',
            })
            TagFunction.enviarTagueamentoWeb({
              element_name: 'Simular melhor condição',
              section_name: 'Oferta de seguro',
              element_action: 'click button',
              c_page: window.location.href,
              redirect_url: `${window.location.origin}${routes.RESULTADO_SIMULACAO}`,
              step: 'null',
            })
            selecionarParcelasPorSimulacao()
          }}
        >
          Simular melhor condição
        </Button>
      }
    >
      <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="24px 0">
        <TextParagraph variant="headline-h2">
          Mantenha o seu refinanciamento protegido
        </TextParagraph>
      </ColorWrapper>

      <Box
        key="simulacao-seguro"
        onClick={() => dispatch(selectSimulacao(TipoEmprestimo.CREDITO_PROTEGIDO))}
        actived={simulacaoSelecionada === TipoEmprestimo.CREDITO_PROTEGIDO}
        justify="space-between"
        padding="16px"
      >
        <div>
          <Tag theme="green" size="small" intensity="dark">
            Mais segurança pra você
          </Tag>
          <ColorWrapper color={Colors.GRAY500} fontWeight="700" margin="8px 0 0 0">
            <TextParagraph variant="body-3">Refinanciamento protegido</TextParagraph>
          </ColorWrapper>

          <ListaDescricao
            iconLeft={<ISafe width={24} height={24} color={Colors.GRAY500} />}
            style={{ marginBottom: '8px', marginTop: '16px' }}
          >
            <ColorWrapper color={Colors.GRAY500} margin="0">
              <TextParagraph variant="caption-1">
                Quitação em caso de morte ou invalidez
              </TextParagraph>
            </ColorWrapper>
          </ListaDescricao>
          <ListaDescricao
            iconLeft={<PrepaymentOfReceivables width={24} height={24} color={Colors.GRAY500} />}
          >
            <ColorWrapper color={Colors.GRAY500} margin="0">
              <TextParagraph variant="caption-1">Crédito liberado em até 48 horas</TextParagraph>
            </ColorWrapper>
          </ListaDescricao>
        </div>
        <Radio
          id="input-radio-seguro"
          onChange={() => dispatch(selectSimulacao(TipoEmprestimo.CREDITO_PROTEGIDO))}
          checked={simulacaoSelecionada === TipoEmprestimo.CREDITO_PROTEGIDO}
          value=""
        />
      </Box>

      <Box
        key="simulacao"
        onClick={() => dispatch(selectSimulacao(TipoEmprestimo.SEM_CREDITO_PROTEGIDO))}
        actived={simulacaoSelecionada === TipoEmprestimo.SEM_CREDITO_PROTEGIDO}
        margin="24px 0 0 0"
        padding="16px"
        justify="space-between"
        align="flex-end"
      >
        <ColorWrapper color={Colors.GRAY500} fontWeight="700">
          <TextParagraph variant="body-3">Continuar sem proteção</TextParagraph>
        </ColorWrapper>

        <Radio
          id="input-radio"
          onChange={() => dispatch(selectSimulacao(TipoEmprestimo.SEM_CREDITO_PROTEGIDO))}
          checked={simulacaoSelecionada === TipoEmprestimo.SEM_CREDITO_PROTEGIDO}
          value=""
        />
      </Box>
    </Page>
  )
}
export default OfertaSeguro
