import { addMonths, addDays, differenceInYears, format } from 'date-fns'

import { setTotalParcelas } from '@store/refinanciamento/simulacao/actions'
import { InfoParcelas } from '@store/refinanciamento/simulacao/types'
import { BridgeService } from '@services/bridge'

export const getParcelaAtualSimulacoes = (
  simulations: InfoParcelas[],
  numberOfInstallments?: number,
) => {
  let parcelaReturn: InfoParcelas

  if (!numberOfInstallments) {
    parcelaReturn = simulations?.reduce(
      (simulacaoAccumulator: InfoParcelas, simulacaoCurrent: InfoParcelas) => {
        if (simulacaoAccumulator.numberOfInstallments < simulacaoCurrent.numberOfInstallments) {
          return simulacaoCurrent
        }
        return simulacaoAccumulator
      },
    )
    setTotalParcelas(parcelaReturn.numberOfInstallments)
  } else {
    parcelaReturn = simulations?.find(
      (simulacao) => simulacao.numberOfInstallments === numberOfInstallments,
    ) || {
      numberOfInstallments: 0,
      requestedAmount: 0,
      installmentAmount: 0,
      availableAmount: 0,
      amountIOF: 0,
      percentageIOF: 0,
      financedAmount: 0,
      cETAm: 0,
      cETAa: 0,
      monthlyNominalRate: 0,
      yearlyNominalRate: 0,
      contractualInterest: 0,
      lastDueDate: '',
      firstDueDate: '',
      moneyChange: 0,
      insuranceFee: 0,
    }
  }
  return parcelaReturn
}

export const validaEnvHomeByBridge = (origemGoBack: string, isApp: boolean) => {
  if (isApp) {
    if (origemGoBack === 'emprestimosanguelaranja')
      return `${process.env.REACT_APP_SIMULADOR_CONSIGNADO}/novo?origem=app&familia=publico&origemgoback=emprestimo&sangueLaranja=true`

    return `${process.env.REACT_APP_SIMULADOR_CONSIGNADO}/novo?origem=app&familia=publico&origemgoback=emprestimo`
  }
  return `${process.env.REACT_APP_EMPRESTIMO_NOVO}`
}

export const idadePelaDataNascimento = (dataNascimento: string): number => {
  const data = new Date()
  const nascimento = new Date(dataNascimento)
  let idade = data.getFullYear() - nascimento.getFullYear()
  const mes = data.getMonth() - nascimento.getMonth()

  if (mes < 0 || (mes === 0 && data.getDate() < nascimento.getDate())) {
    idade -= 1
  }

  return idade
}

export const timestampToDatePt = (timestamp: number) => {
  if (!timestamp) return null
  const getDate =
    new Date(timestamp).getUTCDate() < 10
      ? `0${new Date(timestamp).getUTCDate()}`
      : new Date(timestamp).getUTCDate()
  const getMonth =
    new Date(timestamp).getMonth() + 1 < 10
      ? `0${new Date(timestamp).getMonth() + 1}`
      : new Date(timestamp).getMonth() + 1
  const getYear = new Date(timestamp).getFullYear()
  return `${getDate}/${getMonth}/${getYear}`
}

export const DateFormat = (date: Date, pattern = 'YYYY-MM-DDTHH:mm:ss.SSSZZ') =>
  format(date, pattern)

export const CurrentDate = () => DateFormat(new Date())

export const describeAge = (date: Date, currentDate = new Date(), hasSumDate = false) => {
  const now = currentDate
  let birthday = addMonths(date, 1)
  birthday = addDays(birthday, 1)
  let years = differenceInYears(now, birthday)

  if (
    date.getMonth() + 1 === currentDate.getMonth() + 1 &&
    date.getDate() > currentDate.getDate() &&
    hasSumDate
  ) {
    years += 1
  }
  return years
}

/**
 * Converte um valor monetário que esta em string ex: 1.250,45
 * para um valor float ex: 1250.45
 * @param valueString String
 * @returns ex: 1250.45
 */
export const convertStringValueToNumber = (valueString: string) => {
  const removedDots = valueString.replace('.', '')
  const replacedCommas = removedDots.replace(',', '.')
  return parseFloat(replacedCommas)
}

export const ConvertDateUTC = (date: string) => {
  const newDate = new Date(date)
  newDate.setTime(newDate.getTime() + newDate.getTimezoneOffset() * 60 * 1000)
  return newDate
}

export const openLinkByEnviroment = async (url: string) => {
  if (BridgeService.isBrowser()) {
    window.location.href = url
  } else {
    await BridgeService.openDeepLink(url)
  }
}

export const fileToBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

export function convertRotationToDegrees(rotation: number): number {
  let rotationInDegrees = 0
  switch (rotation) {
    case 8:
      rotationInDegrees = 270
      break
    case 6:
      rotationInDegrees = 90
      break
    case 3:
      rotationInDegrees = 180
      break
    default:
      rotationInDegrees = 0
  }
  return rotationInDegrees
}

export const getOrientation = (
  imageFile: File,
  onRotationFound: (rotationInDegrees: number) => void,
) => {
  const reader = new FileReader()
  reader.onload = (event: ProgressEvent) => {
    if (!event.target) {
      return
    }

    const innerFile = event.target as FileReader
    const view = new DataView(innerFile.result as ArrayBuffer)

    if (view.getUint16(0, false) !== 0xffd8) {
      onRotationFound(-2)
    }

    const length = view.byteLength
    let offset = 2

    while (offset < length) {
      if (view.getUint16(offset + 2, false) <= 8) {
        onRotationFound(-1)
      }
      const marker = view.getUint16(offset, false)
      offset += 2

      if (marker === 0xffe1) {
        offset += 2
        if (view.getUint32(offset, false) !== 0x45786966) {
          onRotationFound(-1)
        }

        const little = view.getUint16((offset += 6), false) === 0x4949
        offset += view.getUint32(offset + 4, little)
        const tags = view.getUint16(offset, little)
        offset += 2
        for (let i = 0; i < tags; i += 1) {
          if (view.getUint16(offset + i * 12, little) === 0x0112) {
            onRotationFound(view.getUint16(offset + i * 12 + 8, little))
          }
        }
      } else if ((marker & 0xff00) !== 0xff00) {
        break
      } else {
        offset += view.getUint16(offset, false)
      }
    }
    onRotationFound(-1)
  }
  reader.readAsArrayBuffer(imageFile)
}

export const rotateImageOrientation = async (
  imageSrc: string,
  orientation: number,
): Promise<string> => {
  const image = new Image()
  image.src = imageSrc

  const canvas = document.createElement('canvas')
  const ctx = canvas?.getContext('2d')

  return new Promise((resolve) => {
    image.addEventListener('load', () => {
      if (orientation > 4 && orientation < 9) {
        canvas.width = image.height
        canvas.height = image.width
      } else {
        canvas.width = image.width
        canvas.height = image.height
      }

      switch (orientation) {
        case 2:
          ctx?.transform(-1, 0, 0, 1, image.width, 0)
          break
        case 3:
          ctx?.transform(-1, 0, 0, -1, image.width, image.height)
          break
        case 4:
          ctx?.transform(1, 0, 0, -1, 0, image.height)
          break
        case 5:
          ctx?.transform(0, 1, 1, 0, 0, 0)
          break
        case 6:
          ctx?.transform(0, 1, -1, 0, image.height, 0)
          break
        case 7:
          ctx?.transform(0, -1, -1, 0, image.height, image.width)
          break
        case 8:
          ctx?.transform(0, -1, 1, 0, 0, image.width)
          break
        default:
          break
      }
      ctx?.drawImage(image, 0, 0)
      resolve(canvas?.toDataURL('image/jpeg'))
    })
  })
}

export const TiposConta = [
  {
    value: '1',
    label: 'Conta Corrente',
  },
  {
    value: '2',
    label: 'Conta Poupança',
  },
]
export const findTipoConta = (tipoConta: string | number | undefined) =>
  TiposConta.find((item) => item.value === String(tipoConta) || item.label === tipoConta)

export const TipoCreditoApiInss = [
  {
    value: '1',
    label: 'Cartão Magnético',
  },
  {
    value: '2',
    label: 'Conta Corrente',
  },
]

export const findTipoCreditoApiInss = (tipoCredito: string | number | undefined) =>
  TipoCreditoApiInss.find(
    (item) =>
      item.value === String(tipoCredito) ||
      item.label.toUpperCase() === String(tipoCredito).toUpperCase(),
  )

/**
 * Converte data string para data no formato yyyy-mm-dd
 * @param value String
 * @returns yyyy-mm-dd
 */
export const datePtToEn = (date: string) =>
  date
    ? format(
        new Date(
          Number(date.substring(6)),
          Number(date.substring(3, 5)) - 1,
          Number(date.substring(0, 2)),
        ),
        'yyyy-MM-dd',
      )
    : ''

export const base64toBlob = (base64: string, contentType = 'image/jpeg', sliceSize = 512) => {
  const byteCharacters = atob(base64.replace(/data:.+?,/, ''))
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const logout = () => {
  window.location.href = `${process.env.REACT_APP_EMPRESTIMO_NOVO}`
}
