import React, { useCallback, useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Text } from '@interco/inter-ui/components/Text'
import { Colors } from '@utils/Colors'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Button } from '@interco/inter-ui/components/Button'
import PdfFile from '@interco/icons/core/files/ic_file_pdf'
import ChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'
import routes from '@routes/RefinV2/routes'
import { inssStartAsync } from '@store/refinanciamento/consultaMargemInss/actions'
import {
  setDadosSimulacaoAlterados,
  simulacaoRequest,
  trackingRefin,
} from '@store/refinanciamento/simulacao/actions'
import { CallbackSimulacaoType, InfoParcelas } from '@store/refinanciamento/simulacao/types'
import { PendenciaEnumV3, StepRefin } from '@utils/enums'
import Tag from '@utils/Tag'
import { ApplicationState } from '@store/types'

import { BoxCustom, Line } from './styles'

interface OwnProps {
  setShowBottomsheetInss: React.Dispatch<React.SetStateAction<boolean>>
}

const BottomsheetInss = ({ setShowBottomsheetInss }: OwnProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { loadingInssApis } = useSelector(
    (state: ApplicationState) => state.refinanciamento.consultaMargemInss,
  )
  const { loadingSimulacao, errorSimulacao } = useSelector(
    (state: ApplicationState) => state.refinanciamento.simulacao,
  )
  const navegaSelecaoSimulacao = useCallback(
    (simulacoes: InfoParcelas[], simulacoesComSeguro: InfoParcelas[]) => {
      if (simulacoesComSeguro?.length > 0) {
        navigate(routes.OFERTA_SEGURO)
      } else if (simulacoes?.length > 0) {
        dispatch(setDadosSimulacaoAlterados(true))
        navigate(routes.RESULTADO_SIMULACAO)
      } else {
        navigate(routes.NAO_LIBERADO)
      }
    },
    [dispatch, navigate],
  )

  useEffect(() => {
    if (errorSimulacao) {
      setShowBottomsheetInss(false)
    }
  }, [errorSimulacao, setShowBottomsheetInss])

  const callSimulation = useCallback(() => {
    dispatch(
      simulacaoRequest({
        callback: async ({
          pending,
          simulations,
          simulationsWithInsurance,
        }: CallbackSimulacaoType) => {
          dispatch(trackingRefin(StepRefin.SIMULAR_CONTRATOS))
          if (pending === PendenciaEnumV3.PENDENCIA_VALIDACAO) {
            await new Promise((resolve) => setTimeout(resolve, 5000))
            callSimulation()
          } else if (pending === PendenciaEnumV3.PENDENCIA_IRREVERSIVEL) {
            setLoading(false)
            setShowBottomsheetInss(false)
            navigate(routes.NAO_LIBERADO)
          } else if (pending === PendenciaEnumV3.PENDENCIA_TEIMOSINHA) {
            setLoading(false)
            setShowBottomsheetInss(false)
            navigate(routes.INSS_INDISPONIVEL)
          } else {
            setLoading(false)
            setShowBottomsheetInss(false)
            navegaSelecaoSimulacao(simulations, simulationsWithInsurance)
          }
        },
      }),
    )
  }, [dispatch, navegaSelecaoSimulacao, navigate, setShowBottomsheetInss])

  return (
    <BottomSheet onClose={() => setShowBottomsheetInss(false)} noSwipe>
      <Line>
        <ColorWrapper fontWeight="600">
          <TextParagraph variant="headline-h3">Autorizar termo</TextParagraph>
        </ColorWrapper>
        <ColorWrapper
          fontWeight="700"
          color={Colors.PRIMARY500}
          onClick={() => {
            setShowBottomsheetInss(false)
          }}
        >
          <TextParagraph variant="body-3">Fechar</TextParagraph>
        </ColorWrapper>
      </Line>

      <ColorWrapper color={Colors.GRAY500} margin="0 auto 24px auto">
        <TextParagraph variant="caption-1">
          Essa autorização nos ajuda a oferecer a melhor condição possível pra você.
        </TextParagraph>
      </ColorWrapper>

      <BoxCustom
        onClick={() => {
          Tag.enviarTagueamentoApp({
            name: 'CONSIGNADO_REFIN_FLUXO',
            ref_figma: '2',
            tela: 'Aceite termo',
            ref_type: 'Botao',
            content_action: 'Toque',
            content_name: 'Termo de Autorização INSS',
            product: 'CONSIGNADO_REFIN_INSS',
          })
          navigate(routes.AUTORIZACAO)
        }}
        actived={false}
      >
        <Text
          variant="body-3"
          style={{
            width: '90%',
            color: Colors.GRAY500,
            fontWeight: 700,
            alignContent: 'flex-start',
          }}
        >
          <PdfFile height={24} width={24} color={Colors.GRAY500} style={{ marginRight: '16px' }} />
          Termo de Autorização INSS
        </Text>
        <ChevronRight height={24} width={24} color={Colors.PRIMARY500} />
      </BoxCustom>

      <div style={{ margin: '24px 0 0 0 ' }}>
        <Text variant="caption-1">Ao continuar, você declara que aceita o termo acima.</Text>
        <Button
          fullWidth
          style={{ marginTop: '8px' }}
          isLoading={loading || loadingInssApis || loadingSimulacao}
          disabled={loading || loadingInssApis || loadingSimulacao}
          onClick={() => {
            Tag.enviarTagueamentoApp({
              name: 'CONSIGNADO_REFIN_FLUXO',
              ref_figma: '2',
              tela: 'Aceite termo',
              ref_type: 'Botao',
              content_action: 'Toque',
              content_name: 'Continuar',
              product: 'CONSIGNADO_REFIN_INSS',
            })
            setLoading(true)
            dispatch(
              inssStartAsync({
                callback: async () => {
                  await new Promise((resolve) => setTimeout(resolve, 5000))
                  callSimulation()
                },
                callbackError: () => {
                  setLoading(false)
                  setShowBottomsheetInss(false)
                },
              }),
            )
          }}
        >
          Continuar
        </Button>
      </div>
    </BottomSheet>
  )
}
export default BottomsheetInss
